import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-patient-log',
  templateUrl: './patient-log.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss', './patient-log.component.scss']
})
export class PatientLogComponent implements OnInit {

  id:any = '0';
  logMode:any = 1;
  limit:any = 20;
  offset:any = 0;
  data:any = {};
  paymentStatus:any = "";
  selectedDate:any = "";
  consultant:any = 0;
  therapists:any = [];
  selectedVisit:any = {report:{id:0, body:""}}

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.logMode = this.dataService.localData.selectedPatientLogMode;
    this.offset = this.dataService.localData.selectedPatientLogOffset;
    this.loadData();
    this.loadTherapists();
  }


  setLogMode(mode){
    this.logMode = mode;
    this.dataService.localData.selectedPatientLogMode = this.logMode;
    this.offset = 0;
    this.loadData();

  }
  selectVisit(visit){
		this.selectedVisit = visit;
		console.log(this.selectedVisit);
	}

  loadData(){
    if(this.logMode ==1 || this.logMode == 2){
      this.dataService.getClientAppointments(this.id, this.logMode, this.limit, this.offset, this.selectedDate, this.consultant ).subscribe( (data: any[] )=> {
        this.data = data;
      });
    } else if(this.logMode ==3){
      this.dataService.getPatientBills(this.id, this.paymentStatus, this.limit, this.offset).subscribe( (data: any[] )=> {
        this.data = data;
        console.log(this.data);
      });
    }
  }

  loadTherapists(){
    this.dataService.getTherapists(0, 1).subscribe( (data: any = {status:0} )=> {
      if(data.status == 0){
        this.therapists = data;
        console.log(data);
      }
      
      
    });
  }

  paginate(dir){
    if(dir == 2) {
      this.offset += this.limit;
    } else {
      this.offset -= this.limit;
    }

    this.dataService.localData.selectedPatientLogOffset = this.offset;

    this.loadData();

  }

}
