import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss']
})
export class BillComponent implements OnInit {

	bill:any = {status:0, patient:""};
	id:any = '0';
	therapists:any = [];
  	selectedTherapist:any = 0;	
	branch:any = {id:0, name:""};
	logo:any = null;
	address:any = "";
	selectedPaymentMode:any = "";
	selectedReturnItem:any = {};
	walletBalance:any = 0;
	paymentModes:any = [
		{
			mode:0,
			amount:0
		}
	]

	partialPaymentAmount:any = "";
	partialPayments:any = {
		totalPaid:0,
		balance:0,
		list:[],
		today:""
	}
	receiptVariables:any = {
		payer_salutation:"",
		pronoun:"",
		relationship:"",
	}
	

	constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService, private location: Location) { }

	ngOnInit() {

		this.id = (this.route.snapshot.paramMap.get('id') != null) ? this.route.snapshot.paramMap.get('id') : 0;
		this.branch = (localStorage.getItem("branch") != null) ? JSON.parse(localStorage.getItem("branch")) : {id:0, name:""}; 

		this.loadBill();
		this.loadTherapists();
		this.loadLogo();
	}

	loadBill(){

		this.dataService.getSingleBill(this.id).subscribe( (data: any )=> {
			this.bill = data.data;
			this.loadWalletBalance(data.data.patient_id);

			if(this.bill.allow_partial_receipts){
				this.loadPartialPayments();
			}

			if(this.bill.load_receipt_variables){
				this.loadReceiptVariables();
			}
		});

	}

	loadPartialPayments(){

		this.dataService.getBillPartialPayments(this.id).subscribe( (data: any )=> {
			this.partialPayments = data;
		
		});

	}

	loadReceiptVariables(){

		this.dataService.getReceiptVariables(this.bill.patient_id).subscribe( (data: any )=> {
			this.receiptVariables = data;
		
		});

	}

	loadWalletBalance(patient_id){
		this.dataService.getClientWalletBalance(patient_id).subscribe( (data: any )=> {
			if(data.status == 0){
				this.walletBalance = data.balance;
			}
			
		});
	}

	loadTherapists(){
		this.dataService.getTherapists(this.branch.id).subscribe( (data: any = {status:0} )=> {
		if(data.status == 0){
			this.therapists = data;
		}
		
		
		});
	}

loadLogo(){
	this.dataService.getLogoData().subscribe( (data: any = {status:0, logo_data:""} )=> {
		if(data.status == 0){
			this.logo = data.logo_data;
			this.address = data.address;
		}
		
		
	});
}

submitCons(data){
	data.bill_id = this.id;
	data.field = "consultant_id";
	this.dataService.postUpdateBill(data).subscribe( (res:any) => {
		if(res.status == 0) {
		$("#modal-edit-cons").modal('hide');
		$(".btn-close-modal").trigger("click");
		  this.loadBill();
		} else {
		  alert(res.msg);
		}

	  }); 

}

submitPm(data){
	
	
	if(data.mode != 0){
		data.bill_id = this.bill.id;
		this.dataService.postMarkBillPaid(data).subscribe( (res:any) => {
		if(res.status == 0) {
			  alert(res.msg);
			
			this.loadBill();
			$("#modal-edit-pm").modal('hide');
			$(".btn-close-modal").trigger("click");
		} else {
		  alert(res.msg);
		}
		} );
	} else {
		alert("Select a mode of payment to continue.");
	}
	
}

submitDiscount(data){
	if(!isNaN(data.discount)){

		data.bill_id = this.bill.id;
		this.dataService.postUpdateBillDiscount(data).subscribe( (res:any) => {
		if(res.status == 0) {
			alert(res.msg);
			this.loadBill();
			$("#modal-edit-discount").modal('hide');
			$(".btn-close-modal").trigger("click");
		} else {
		  alert(res.msg);
		}
		} );

	} else {
		alert("Discount must be a number");
	}
}

submitDate(data){
	data.bill_id = this.bill.id;
	data.bill_id = this.id;
	data.field = "bill_date";
	this.dataService.postUpdateBill(data).subscribe( (res:any) => {
	if(res.status == 0) {
		this.loadBill();
		$("#modal-edit-billdate").modal('hide');
		$(".btn-close-modal").trigger("click");
	} else {
		alert(res.msg);
	}
	} );
}


selectReturnItem(item){
	this.selectedReturnItem = item;
	
}

submitReturn(input){
	console.log(input);
	let allowed = this.selectedReturnItem.qty - this.selectedReturnItem.returned;
	if(input.current_return > allowed){
		
		alert("You can't return more than " + allowed + " quantity of this item" );
	} else {
		let data = {
			bill_id:this.bill.id,
			bc_id:this.selectedReturnItem.id,
			stock_id:this.selectedReturnItem.stock_id,
			return_qty:input.current_return,
			rate:this.selectedReturnItem.rate
		}

		this.dataService.postPatientReturnStock(data).subscribe( (res:any) => {
			if(res.status == 0) {
				alert(res.msg);
				this.loadBill();
				$(".btn-close-modal").trigger("click");
			} else {
			  alert(res.msg);
			}
			} );

		
	}
}


  /*print */

  printTbcBill(){
	//@ts-ignore
	var doc = new jsPDF();
	var y = 10;
	var yr = 30;

	//Logo
	doc.addImage(this.logo, 'PNG', 30,0,155,37); // logo dimensions: 210x50mm
	y+=30; yr+=20;
	doc.setFontSize(10);
	doc.setFontType('bold');
	doc.text(100, y, 'Tax Invoice');
	y+=10;

	//from
	doc.setFontSize(8);
	doc.text(10,y, "Talking Brains Center FZE");
	y+=5;
	doc.setFontType('normal');
	doc.text(10,y, "Office 505, 5/F, Apricot Tower,");
	y+=5;
	doc.text(10,y, "Silicon Oasis,");
	y+=5;
	doc.text(10,y, "Dubai, UAE.");
	y+=5;
	doc.text(10,y, "Emirate: Dubai");
	y+=5;
	doc.text(10,y, "TRN: 100332404100003");

	//buyer
	y+=10;
	doc.text(10, y, "Paid by");
	y+=5;
	doc.setFontType('bold');
	doc.text(10, y, this.bill.patient);
	doc.setFontType('normal');
	y+=5;
	doc.text(10,y, this.bill.phone);
	y+=5;
	doc.text(10,y, "Emirate:");
	doc.text(35, y, this.bill.city);
	y+=5;
	doc.text(10,y, "Country:");
	doc.text(35,y, this.bill.country);
	y+=5;
	doc.text(10,y, "Place of Supply:");
	doc.text(35,y, "Dubai, UAE");

	//right side of header
	doc.setFontType('bold');
	doc.text(135, yr, "Invoice No.");
	doc.text(169, yr, "Dated");
	doc.setFontType('normal');
	yr+=5;
	doc.text(135, yr, this.bill.bill_no.toString());
	doc.text(169, yr, this.bill.bill_date);
	
	yr+=10;
	doc.setFontType('bold');
	doc.text(135, yr, "Therapist");
	doc.text(169, yr, "Mode of Payment");
	doc.setFontType('normal');
	yr+=5;
	doc.text(135,yr, this.bill.consultant);
	doc.text(169, yr, this.bill.payment_mode);

	//particulars

	y+=10;
	doc.setFontType('bold');
	doc.text(10,y, "Sl No.");
	doc.text(26, y, "Particulars");
	doc.text(121, y, "Quantity", 'right');
	doc.text(140, y, "Rate", 'right');
	doc.text(159, y, "Amount", 'right');
	doc.text(185, y, "VAT %", 'right');

	doc.setFontType('normal');
	y+=7;
	var counter = 1;
	this.bill.contents.forEach(element => {
		doc.text(10,y, counter.toString());
		doc.text(26, y, element.description);
		doc.text(121, y, element.qty.toString(), 'right' );
		doc.text(140, y, element.rate.toString(), 'right');
		doc.text(159, y, element.amount.toString(), 'right');
		doc.text(185, y, "0%", 'right');
		
		if(this.bill.type == 2){
			doc.text(26, y+5, "Batch: " + element.batch + " Expiry: " + element.expiry );
			
			y += 10;
		} else {
			y+=5;
		}
		
		
		counter++;
	});

	
	
	
	

	//Total;

	doc.setFontType('bold');
	y+=20;
	doc.text(121, y, "Total", 'right');
	doc.setFontSize(10);
	doc.text(159, y, "AED "+this.bill.total.toString(), 'right');

	doc.setFontType('normal');
	doc.setFontSize(8);

	y+=10;
	doc.text(10, y, "Amount chargeable (in words)");
	y+=5;
	doc.setFontSize(9);
	doc.setFontType('bold');
	//@ts-ignore
	doc.text(10, y, "UAE Dirham "+number2words(this.bill.total)+" Only (AED " +this.bill.total.toString() + ")"  );
	
	


	//output
	var string = doc.output('datauristring');
	var newWindow = window.open("", "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
	newWindow.document.write(
	'<html><body><center>' +
	
	'</center><br>' +
	'<object width=100% height=100% type="application/pdf" data="'+string+'">' +
	'<embed type="application/pdf" src="'+string+'" id="embed_pdf"></embed>' +
	'</object></body></html>');
	newWindow.window.focus();

  }

  printTbcReceipt(){
	//@ts-ignore
	var doc = new jsPDF("l", "mm", [148,210]);
	var y = 10;
	var yr = 30;

	//Logo
	doc.addImage(this.logo, 'PNG', -20,0,155,37); // logo dimensions: 210x50mm

	doc.setFontSize(12);
	doc.setFontStyle('italic');
	y += 2;
	doc.text("Office 505, Appricot Tower, Silicon Oasis, Dubai", 195, y, 'right');
	y +=6;
	doc.text("P.O Box: 393984, 04 320 9307 / 055 896 7482", 195, y, 'right');
	y +=6;
	doc.text("www.talkingbrainscenter.com", 195, y, 'right');
	y +=6;
	doc.text("TRN: 100332404100003", 195, y, 'right');

	y += 15;
	doc.setFontStyle('normal');

	doc.text("Bill Date: " + this.bill.bill_date_formatted, 10, y);
	doc.text("Receipt No.: " + this.bill.bill_no.toString(), 195, y, 'right');
	if(this.bill.is_paid == 1){
		y+= 7;
		doc.text("Payment Date: " + this.bill.payment_date, 10, y);
	}
	

	y += 25;
	var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
	var ParaWidth=pageWidth*0.95; /* para width will 85% of the page width. */

	let receiptBody = "We have received from " + this.receiptVariables.payer_salutation + " "  +this.bill.payer_name+" for "+ this.receiptVariables.pronoun + " " + this.receiptVariables.relationship + " "+this.bill.patient+" an amount of AED "+this.bill.payable+" for the following service/s provided in the center:";

	
	
	var lines = doc.splitTextToSize(receiptBody, ParaWidth);
	doc.text(10, y , lines);
	console.log("lines", lines);

	y += 5*(lines.length + 1);

	

	let description = "Description: ";

	this.bill.contents.forEach(row => {
		description += row.description;
		if(row.therapist.length > 0 || row.duration.length > 0)	{
			description += " (";

			if(row.therapist.length > 0){
				description += "Therapist: " + row.therapist + "; ";
			}

			if(row.duration.length > 0){
				description += "Duration: " + row.duration;
			}

			description += ")";
		}
		description += ". ";
	});

	var lines = doc.splitTextToSize(description, ParaWidth);
	doc.text(10, y , lines);

	y += 5*(lines.length + 1);

	if(this.bill.is_paid == 1){
		doc.text("Payment made by: " + this.bill.payment_mode.name, 10, y);	
	}
	

	let yfooter = 135;

	doc.line(10, yfooter, 80, yfooter);
	doc.text("Payment received by", 20, yfooter+5);

	doc.line(120, yfooter, 200, yfooter);
	doc.text("Authorized signature", 140, yfooter+5);


	//output
	var string = doc.output('datauristring');
	var newWindow = window.open("", "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
	newWindow.document.write(
	'<html><body><center>' +
	
	'</center><br>' +
	'<object width=100% height=100% type="application/pdf" data="'+string+'">' +
	'<embed type="application/pdf" src="'+string+'" id="embed_pdf"></embed>' +
	'</object></body></html>');
	newWindow.window.focus();

  }

  printTbcPartialReceipt(row){
	//@ts-ignore
	var doc = new jsPDF("l", "mm", [148,210]);
	var y = 10;
	var yr = 30;

	//Logo
	doc.addImage(this.logo, 'PNG', -20,0,155,37); // logo dimensions: 210x50mm

	doc.setFontSize(12);
	doc.setFontStyle('italic');
	y += 2;
	doc.text("Office 505, Appricot Tower, Silicon Oasis, Dubai", 195, y, 'right');
	y +=6;
	doc.text("P.O Box: 393984, 04 320 9307 / 055 896 7482", 195, y, 'right');
	y +=6;
	doc.text("www.talkingbrainscenter.com", 195, y, 'right');
	y +=6;
	doc.text("TRN: 100332404100003", 195, y, 'right');

	y += 15;
	doc.setFontStyle('normal');

	doc.text("Bill Date: " + this.bill.bill_date_formatted, 10, y);
	doc.text("Receipt No.: " + this.bill.bill_no.toString() + "-" + row.receipt_no.toString(), 195, y, 'right');
	y+= 7;
	doc.text("Payment Date: " + row.date, 10, y);

	y += 25;
	var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
	var ParaWidth=pageWidth*0.95; /* para width will 85% of the page width. */

	//let receiptBody = "We have received from Mr./Mrs. "+this.bill.payer_name+" for his/her son/daughter/himself "+this.bill.patient+" an amount of AED "+row.amount.toString()+" for the following service/s provided in the center:";
	let receiptBody = "We have received from " + this.receiptVariables.payer_salutation + " "  +this.bill.payer_name+" for "+ this.receiptVariables.pronoun + " " + this.receiptVariables.relationship + " "+this.bill.patient+" an amount of AED "+row.amount.toString()+" for the following service/s provided in the center:";
	
	
	var lines = doc.splitTextToSize(receiptBody, ParaWidth);
	doc.text(10, y , lines);
	console.log("lines", lines);

	y += 5*(lines.length + 1);

	//let description = "Description: Speech therapy (therapist: Reine Bou Issa; Duration: 60 minutes), Occupational therapy (therapist: Reine Bou Issa; Duration: 60 minutes)";

	let description = "Description: ";

	this.bill.contents.forEach(row => {
		description += row.description;
		if(row.therapist.length > 0 || row.duration.length > 0)	{
			description += " (";

			if(row.therapist.length > 0){
				description += "Therapist: " + row.therapist + "; ";
			}

			if(row.duration.length > 0){
				description += "Duration: " + row.duration;
			}

			description += ")";
		}
		description += ". ";
	});

	var lines = doc.splitTextToSize(description, ParaWidth);
	doc.text(10, y , lines);

	y += 5*(lines.length + 1);

	/*if(this.bill.is_paid == 1){
		doc.text("Payment made by: " + this.bill.payment_mode.name, 10, y);	
	} */
	

	let yfooter = 135;

	doc.line(10, yfooter, 80, yfooter);
	doc.text("Payment received by", 20, yfooter+5);

	doc.line(120, yfooter, 200, yfooter);
	doc.text("Authorized signature", 140, yfooter+5);


	//output
	var string = doc.output('datauristring');
	var newWindow = window.open("", "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
	newWindow.document.write(
	'<html><body><center>' +
	
	'</center><br>' +
	'<object width=100% height=100% type="application/pdf" data="'+string+'">' +
	'<embed type="application/pdf" src="'+string+'" id="embed_pdf"></embed>' +
	'</object></body></html>');
	newWindow.window.focus();

  }

  printBill(){
	//@ts-ignore
	var doc = new jsPDF();
	var y = 10;
	var yr = 30;

	//Logo
	if(this.logo != null){
		doc.addImage(this.logo, 'PNG', 30,0,155,37); // logo dimensions: 210x50mm
		y+=30; yr+=20;
	}
	
	
	doc.setFontSize(10);
	doc.setFontType('bold');
	doc.text(100, y, this.bill.meta.inv_prefix + 'Invoice');
	y+=10;

	//from
	doc.setFontSize(8);
	doc.text(10,y, this.bill.name);
	y+=5;
	doc.setFontType('normal');
	if(this.bill.address != null){

		if(this.bill.address.length < 40){
			doc.text(10,y, this.bill.address);
			y+=5;
		} else {
			var splitText = doc.splitTextToSize(this.bill.address, 50)
			
			for (var i = 0, length = splitText.length; i < length; i++) {
			// loop thru each line and increase
			doc.text(10, y, splitText[i]);
			y+=5;
			}
		}

		
	}
	
	if(this.bill.phone != null){
		doc.text(10,y, "Phone: " + this.bill.phone);
		y+=5;
	}

	//buyer
	y+=10;
	doc.text(10, y, "Client");
	y+=5;
	doc.setFontType('bold');
	doc.text(10, y, this.bill.patient);
	doc.setFontType('normal');
	y+=5;

	if(this.bill.phone != null){
		doc.text(10,y, this.bill.patient_phone);
		y+=5;
	}

	
	
	//right side of header
	doc.setFontType('bold');
	doc.text(135, yr, "Invoice No.");
	doc.text(169, yr, "Dated");
	doc.setFontType('normal');
	yr+=5;
	doc.text(135, yr, this.bill.bill_no.toString());
	doc.text(169, yr, this.bill.bill_date);
	
	yr+=10;
	doc.setFontType('bold');
	doc.text(135, yr, "Consultant");
	doc.text(169, yr, "Mode of Payment");
	doc.setFontType('normal');
	yr+=5;
	doc.text(135,yr, this.bill.consultant);
	doc.text(169, yr, this.bill.payment_mode.name);

	//particulars

	y+=10;
	doc.setFontType('bold');
	doc.text(10,y, "Sl No.");
	doc.text(26, y, "Particulars");
	doc.text(145, y, "HSN/SAC", 'right');
	doc.text(161, y, "Quantity", 'right');
	doc.text(180, y, "Rate", 'right');
	doc.text(199, y, "Amount", 'right');
	

	doc.setFontType('normal');
	y+=7;
	var counter = 1;
	this.bill.contents.forEach(element => {
		doc.text(10,y, counter.toString());
		doc.text(26, y, element.description);
		doc.text(145, y, element.hsn_code.toString(), 'right' );
		doc.text(161, y, element.qty.toString(), 'right' );
		doc.text(180, y, element.rate.toString(), 'right');
		doc.text(199, y, element.amount.toString(), 'right');
		
		if(this.bill.type == 2){
			console.log("pharma bill");
			doc.text(26, y+5, "Batch: " + element.batch + " Expiry: " + element.expiry );
			
			y += 10;
		} else {
			y+=5;
		}
		counter++;
	});

	
	
	
	

	//Total;

	doc.setFontType('bold');
	y+=20;
	doc.text(151, y, "Total", 'right');
	doc.setFontSize(10);
	doc.text(199, y, this.bill.short_c +" "+this.bill.total.toString(), 'right');

	if( Number(this.bill.discount) != 0){

		doc.setFontSize(8);
		y+=7;
		doc.text(151, y, "Discount", 'right');
		doc.setFontSize(10);
		doc.text(199, y, this.bill.short_c +" "+this.bill.discount.toString(), 'right');

		doc.setFontSize(8);
		y+=7;
		doc.text(151, y, "Payable", 'right');
		doc.setFontSize(10);
		doc.text(199, y, this.bill.short_c +" "+this.bill.payable.toString(), 'right');
	}

	if(this.dataService.localData.gstBilling == 1 && this.bill.type == 2){
		doc.setFontSize(8);
		y+=7;
		doc.text(151, y, "CGST", 'right');
		doc.text(199, y, this.bill.short_c +" "+this.bill.cgst.toString(), 'right');
		y+=5;
		doc.text(151, y, "SGST", 'right');
		doc.text(199, y, this.bill.short_c +" "+this.bill.sgst.toString(), 'right');
	}

	



	doc.setFontType('normal');
	doc.setFontSize(8);

	y+=10;
	doc.text(10, y, "Amount chargeable (in words)");
	y+=5;
	doc.setFontSize(9);
	doc.setFontType('bold');
	//@ts-ignore
	doc.text(10, y, this.bill.long_c+" "+number2words(this.bill.payable)+" Only ("+this.bill.short_c+ " " + this.bill.payable.toString() + ")"  );
	//@ts-ignore
	console.log("total", number2words(this.bill.total));

	//PAN, declaration, signature

	y += 20;

	if(this.bill.companyPan != null && this.bill.companyPan.length > 0){
		y += 5;
		doc.setFontType("normal");
		doc.text(10, y, "Company's PAN:");
		doc.setFontType("bold");
		doc.text(40, y, this.bill.companyPan);
	}

	y += 10;
	doc.setFontType("normal");
	doc.text(10,y, "Declaration");
	doc.text(10, y+5, "We declare that this invoice shows the actual price of the");
	doc.text(10, y+10, "goods described and that all particulars are true and correct.");

	doc.text(199,y, "For "+this.bill.name, 'right');
	doc.text(199, y+20, "Authorised Signatory", 'right');


	//output
	var string = doc.output('datauristring');
	var newWindow = window.open("", "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
	newWindow.document.write(
	'<html><body><center>' +
	
	'</center><br>' +
	'<object width=100% height=100% type="application/pdf" data="'+string+'">' +
	'<embed type="application/pdf" src="'+string+'" id="embed_pdf"></embed>' +
	'</object></body></html>');
	newWindow.window.focus();

  }


  submitBillCancel(data) {
	
	data.bill_id = this.id;
	console.log(data);

	this.dataService.postCancelBill(data).subscribe( (res:any) => {

		if(res.status == 0) {
		alert(res.msg);
		$('#modal-cancel-bill').modal('hide');
		$(".btn-close-modal").trigger("click");
		
		this.loadBill();
		} else {
		alert(res.msg);
		}

	} );


 }

 updatePayer(){
	let input = prompt("Enter payer name", this.bill.payer_name);

	if(input){
		let data = {patient_id:this.bill.patient_id, payer_name:input}
		this.dataService.postUpdatePayerName(data).subscribe( (res:any) => {

			if(res.status == 0) {
				this.bill.payer_name = input;
			} else {
				alert(res.msg);
			}
	
		} ); 
	}

 }

 setPayableAmount(){
	this.paymentModes[0].amount = this.bill.payable;
 }

 addPaymentMode(){
	this.paymentModes.push({
		mode:0,
		amount:0
	})
	this.splitAmount(this.paymentModes[0], 0);
 }

 splitAmount(mode, index){
	let amt = 0;
	amt = this.bill.payable - mode.amount;
	this.paymentModes[1-index].amount = amt.toFixed(2);
 }

 settleBill(){
	let data = {
		billId:this.bill.id,
		paymentModes:this.paymentModes
	}

	this.dataService.postSettleBill(data).subscribe( (res:any) => {
		if(res.status == 0) {
			$(".btn-close-modal").trigger("click");
			this.loadBill();
		} else {
		  alert(res.msg);
		}

	}); 
 }


 submitPartialPayment(data){
	data.bill_id = this.id;
	
	this.dataService.postSubmitBillPartialPayment(data).subscribe( (res:any) => {
		if(res.status == 0) {
			this.partialPaymentAmount = "";
			this.loadPartialPayments();
		} else {
		  alert(res.msg);
		}

	}); 
 }

 submitRvar(data){
	data.patient_id = this.bill.patient_id;

	this.dataService.postUpdateReceiptVariables(data).subscribe( (res:any) => {
		if(res.status == 0) {
			$(".btn-close-modal").trigger("click");
			this.loadReceiptVariables();

		} else {
		  alert(res.msg);
		}

	}); 
 }

  /*

  printBill() {
	var bill = this.bill;
	var imgData = this.logo;
	
	//@ts-ignore
	var doc = new jsPDF();
	
	doc.addImage(imgData, 'PNG', 7,2,40,10);
	doc.setFontSize(8);
	doc.text(200, 6, this.address, 'right');
	//doc.text(200, 10, "Phone: " + bill.phone, 'right');

	doc.text(10, 35, "Patient: " + bill.patient_name + " (" + bill.patient_id + ")"  );
	doc.text(10, 40, "Bill No.: " + bill.bill_no );
	doc.text(10, 45, "Bill Date: " + bill.bill_date );

	doc.setFontType('bold');

	doc.text(10, 55, 'Sl No.');
	doc.text(20, 55, 'Particulars');
	doc.text(100, 55, 'Rate', 'right');
	doc.text(120, 55, 'Quantity', 'right');
	doc.text(140, 55, 'HSN', 'right');
	doc.text(160, 55, 'GST Rate', 'right');
	doc.text(180, 55, 'GST Amt', 'right');
	doc.text(200, 55, 'Amount', 'right');
	doc.setFontType('normal');
	var i = 1;
	var y = 60;

	$.each(bill.bill_data, function(key, index){
		doc.text(10, y, i.toString());
		doc.text(20, y, this.item);
		doc.text(100, y, this.rate, 'right');
		doc.text(120, y, this.qty.toString(), 'right');
		doc.text(140, y, this.hsn_code, 'right');
		doc.text(160, y, this.gst_rate.toString(), 'right');
		doc.text(180, y, this.gst_amount, 'right');
		doc.text(200, y, this.amount, 'right');
		i++;
		y += 5;
	});

	//print total

	doc.setFontType('bold');
	doc.text(184, y+5, "CGST: " + bill.cgst);
	doc.text(184, y+10, "SGST: " + bill.sgst);
	doc.text(172, y+15, "Total Amount: " + bill.total);


	//output
	var string = doc.output('datauristring');
	var newWindow = window.open("", "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
	newWindow.document.write(
	'<html><body><center>' +
	
	'</center><br>' +
	'<object width=100% height=100% type="application/pdf" data="'+string+'">' +
	'<embed type="application/pdf" src="'+string+'" id="embed_pdf"></embed>' +
	'</object></body></html>');
	newWindow.window.focus();

} */



}
