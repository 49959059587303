import { Component, OnInit, NgModule } from '@angular/core';
import { DataService } from '../data.service';
import { FormsModule }   from '@angular/forms';
import { Router } from '@angular/router';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router) { }
  key = null;
  role_id = '0';
  showPassword:any = false;
  ngOnInit() {
  	//
    this.key = localStorage.getItem('key');
    this.role_id = localStorage.getItem('role_id');
    this.dataService.localData.roleId = localStorage.getItem('role_id');

    if (this.key !== null) {
      
      if(this.role_id == '3' || this.role_id == '4') {
          
        //window.location.href = "./dashboard/home";
        this.router.navigate(['/dashboard/home']);

      } else if(this.role_id == '5') {
        
          //window.location.href = "./dashboard/receptionHome";
          this.router.navigate(['/dashboard/receptionHome']);
      } else if(this.role_id == '1' || this.role_id == '9') {
          this.router.navigate(['/dashboard/users']);
      } else if(this.role_id == '2') {
          this.router.navigate(['/dashboard/super-admin']);
      } else if(this.role_id == '8') {
        
        this.router.navigate(['/dashboard/receptionHome']);
    }

    }

  }
  login(data) {
     $('#btn-login').attr('disabled', 'true');
     $('#btn-login').text('Logging in');
	   this.dataService.login(data).subscribe( (res:any) => {
       $('#btn-login').removeAttr('disabled');
       $('#btn-login').text('Login');
      if(res.status == 0) {
        localStorage.setItem( "key", res.key);
        localStorage.setItem("role_id", res.role_id);
        localStorage.setItem("billing", res.billing);
        localStorage.setItem("gstBilling", res.gstBilling);
        this.dataService.localData.roleId = localStorage.getItem('role_id');
        this.dataService.localData.billing = res.billing;
        this.dataService.localData.gstBilling = res.gstBilling;
        if (localStorage.getItem('key') == res.key ) {
          

          if(res.role_id == 3 || res.role_id == 4) {
          
            //window.location.href = "./dashboard/home";
            this.router.navigate(['/dashboard/home']);

          } else if(res.role_id == 5) {
            
              //window.location.href = "./dashboard/receptionHome";
              this.router.navigate(['/dashboard/receptionHome']);
          }  else if(res.role_id == 1 || res.role_id == '9') {
            
              //window.location.href = "./dashboard/receptionHome";
              this.router.navigate(['/dashboard/users']);
          }   else if(res.role_id == 2) {
            
              //window.location.href = "./dashboard/receptionHome";
              this.router.navigate(['/dashboard/super-admin']);
          } else if(res.role_id == 8) {
            
            //window.location.href = "./dashboard/receptionHome";
            this.router.navigate(['/dashboard/receptionHome']);
        }


          
        }

        


      } else {
        alert(res.msg)
      }

     });
  }

  togglePasswordView(val){
    this.showPassword = val;
  }

}
